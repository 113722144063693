<template>
  <div class="scheme-contain">
    <div class="info-box">
      <Form
        :label-width="150"
        :model="Member"
        :rules="ruleValidate"
        ref="member"
      >
        <Row>
          <Col :span="6">
            <Form-item label="省份：">
              <Select
                filterable
                clearable
                v-model="Member.pid"
                placeholder="请选择"
                @on-change="changePro"
              >
                <Option :value="v.id" v-for="v in proList" :key="v.id">{{ v.name }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="城市：" prop="cid">
              <Select
                filterable
                clearable
                v-model="Member.cid"
                placeholder="请选择"
                @on-change="changeCity"
                @click.native="changeBossCity"
              >
                <Option :value="v.id" v-for="v in cityList" :key="v.id">{{ v.name }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="门店：" prop="branch_id">
              <Select
                filterable
                clearable
                v-model="Member.branch_id"
                placeholder="请选择"
                @click.native="changeBossBranch"
              >
                <Option :value="v.id" v-for="v in storeList" :key="v.id">{{ v.name }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>

        <Row>
          <Col :span="6">
            <Form-item label="籍贯：">
              <Cascader :data="nativePlaceList" v-model="native_place" @on-change="handleNative"></Cascader>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="家庭住址：">
              <Input v-model="Member.address" placeholder="请输入家庭住址"></Input>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="文化程度：" prop="education">
              <Select
                clearable
                placeholder="请选择"
                v-model="Member.education"
                @on-change="handleEducation"
              >
                <Option v-for="item in educationList" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>

        <Row>
          <Col :span="6">
            <Form-item label="App 账户(手机号)：" prop="phone">
              <Input v-model="Member.phone" :maxlength="11" placeholder="请输入手机号"></Input>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="身高：" prop="height">
              <Input style="width: 70px;" v-model="Member.height" placeholder="请输入"></Input>&nbsp;&nbsp;cm
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="体重：" prop="weight">
              <Input style="width: 70px;" v-model="Member.weight" placeholder="请输入"></Input>&nbsp;&nbsp;kg
            </Form-item>
          </Col>
        </Row>

        <Row>
          <Col :span="6">
            <Form-item label="备用手机号：" prop="bysjh">
              <Input v-model="Member.bysjh" :maxlength="11" placeholder="请输入备用手机号"></Input>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="婚姻状况：" prop="marita">
              <Select clearable placeholder="请选择" v-model="Member.marita" @on-change="handleMarita">
                <Option v-for="item in maritaType" :value="item.id" :key="item.id">{{ item.name }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="宗教信仰：">
              <Select clearable placeholder="请选择" v-model="Member.religion">
                <Option v-for="item in beliefList" :value="item" :key="item">{{ item }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>

        <Row>
          <Col :span="6">
            <Form-item label="职业：">
              <Select clearable placeholder="请选择" v-model="Member.job">
                <Option v-for="item in jobList" :value="item" :key="item">{{ item }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="原工作单位：">
              <Input v-model="Member.work_unit" placeholder="请输入原工作单位"></Input>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="职称：">
              <Select clearable placeholder="请选择" v-model="Member.positional">
                <Option v-for="item in pTitleList" :value="item" :key="item">{{ item }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="原职务：">
              <Input v-model="Member.job_p" placeholder="请输入原职务"></Input>
            </Form-item>
          </Col>
        </Row>

        <Row>
          <Col :span="12">
            <Form-item label="顾客类型：" prop="member_type1" v-if="power.indexOf('会员管理--修改顾客类型权限') > -1">
              <Row>
                <Col span="11">
                  <Select
                    clearable
                    v-model="Member.member_type1"
                    placeholder="请选择"
                    @on-change="changeType"
                  >
                    <Option :value="v.id" v-for="v in typeList" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </Col>
                <Col span="12" offset="1">
                  <Select
                    clearable
                    multiple
                    v-model="Member.member_type2"
                    placeholder="请选择"
                    @click.native="changeItems"
                  >
                    <Option :value="v.id" v-for="v in typeItemList" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </Col>
              </Row>
            </Form-item>
            <Form-item label="顾客类型：" prop="member_type1" v-else>
              <Row>
                <Col span="10">
                  <Select
                    disabled
                    clearable
                    v-model="Member.member_type1"
                    placeholder="请选择"
                    @on-change="changeType"
                  >
                    <Option :value="v.id" v-for="v in typeList" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </Col>
                <Col span="1">&nbsp;</Col>
                <Col span="13">
                  <Select disabled multiple v-model="Member.member_type2" placeholder="请选择">
                    <Option :value="v.id" v-for="v in typeItemList" :key="v.id">{{ v.name }}</Option>
                  </Select>
                </Col>
              </Row>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="介绍人：" prop="jsr_id">
              <Select
                clearable
                filterable
                v-model="Member.jsr_id"
                placeholder="请选择"
              >
                <Option :value="v.id" v-for="v in userList" :key="v.id">
                  {{ v.name + ' - ' + v.branch_name }}
                </Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col :span="6">
            <Form-item label="与子女同住：" prop="conhabit">
              <Select
                clearable
                placeholder="请选择"
                v-model="Member.conhabit"
                @on-change="handleConhabit"
              >
                <Option value="1">是</Option>
                <Option value="0">否</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item
              label="密度："
              v-if="choConhabit"
              :class="{ 'ivu-form-item-error': conhabitEdit }"
            >
              <Cascader
                :data="densityGradeList"
                v-model="densityVal"
                @on-change="handleChangeDensity"
              ></Cascader>
              <div class="ivu-form-item-error-tip" v-show="conhabitEdit">请选择密度</div>
            </Form-item>
            <Form-item label="密度：" v-else>
              <Cascader :data="densityGradeList" disabled v-model="densityVal"></Cascader>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="ABO属性：" prop="ABO_type">
              <Select clearable v-model="Member.ABO_type" placeholder="请选择">
                <Option :value="v.value" v-for="v in ABOList" :key="v.value">{{ v.label }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :lg="{ span: 12 }">&nbsp;</Col>
        </Row>
        <Row>
          <Col :lg="{ span: 12 }" :md="{ span: 24 }">
            <Form-item label="兴趣活动：">
              <Select clearable placeholder="请选择" multiple v-model="interestArr">
                <Option v-for="item in interestList" :value="item" :key="item">{{ item }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :lg="{ span: 12 }" :md="{ span: 24 }">
            <Form-item label="社会交际：">
              <Select clearable placeholder="请选择" multiple v-model="socialArr">
                <Option v-for="item in socialList" :value="item" :key="item">{{ item }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>

        <Row>
          <Col :lg="{ span: 12 }" :md="{ span: 24 }">
            <Form-item label="特殊贡献：">
              <Select clearable placeholder="请选择" multiple v-model="contributionArr">
                <Option v-for="item in contributionList" :value="item" :key="item">{{ item }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :lg="{ span: 12 }" :md="{ span: 24 }">
            <Form-item label="特殊荣誉：">
              <Select clearable placeholder="请选择" multiple v-model="honorArr">
                <Option v-for="item in honorList" :value="item" :key="item">{{ item }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>

        <Row>
          <Col :span="6">
            <Form-item label="紧急联系人姓名：">
              <Input v-model="Member.mate_name" placeholder="请输入紧急联系人姓名"></Input>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="联系人电话：">
              <Input v-model="Member.mate_card" placeholder="请输入联系人电话"></Input>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="与联系人关系：">
              <Input placeholder="请输入..." v-model="Member.mate_postion"></Input>
            </Form-item>
          </Col>
          <Col :span="6">
            <Form-item label="联系人职业：">
              <Select clearable placeholder="请选择" v-model="Member.mate_job">
                <Option v-for="item in jobList" :value="item" :key="item">{{ item }}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
      </Form>
    </div>
    <div class="symbol-box tac" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">
      <Button type="success" size="large" @click="handleSave('member')">保存</Button>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import City from '@/mixins/City';
import memberService from '@/services/memberService';
import familyService from '@/services/familyService';

export default {
	data() {
		const validateCity = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请选择所属城市'));
			} else if (value === 0) {
				callback(new Error('请选择所属城市'));
			} else {
				callback();
			}
		};
		const validateBranch = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请选择所属分院'));
			} else {
				callback();
			}
		};
		const validateType = (rule, value, callback) => {
			if (!this.Member.member_type1) {
				callback(new Error('请选择顾客类型'));
			} else {
				callback();
			}
		};
		const validatePhone = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入手机号码'));
			} else if (value.length < 11) {
				callback(new Error('手机号码错误'));
			} else if (Number.isNaN(Number(value))) {
				callback(new Error('请输入数字'));
			} else {
				callback();
			}
		};
		const validateHeight = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入身高'));
			} else if (Number.isNaN(Number(value))) {
				callback(new Error('请输入数字'));
			} else {
				callback();
			}
		};
		const validateWeight = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入体重'));
			} else if (Number.isNaN(Number(value))) {
				callback(new Error('请输入数字'));
			} else {
				callback();
			}
		};
		return {
			Member: {
				home: '',
				head_id: '',
				id_card: '',
				name: '',
				gender: '',
				nation: '',
				address: '',
				birthday: '',
				pid: '',
				cid: '',
				member_type1: '',
				member_type2: [],
				branch_id: '',
				grade_id: '',
				density_grade: '',
				density_detail: '',
				jsr_id: '',
				conhabit: '',
				native_place: '',
				religion: '',
				education: '',
				phone: '',
				bysjh: '',
				marita: '',
				positional: '',
				work_unit: '',
				job: '',
				job_p: '',
				height: '',
				weight: '',
				interest: '',
				social: '',
				contribution: '',
				honor: '',
				member_type_id: '',
				member_card_id: '',
				mate_card: '',
				mate_job: '',
				mate_name: '',
				mate_postion: '',
				device_type: '',
				ABO_type: '',
			},
			userList: [],
			typeList: [],
			typeItemList: [],
			typeItem: {},
			native_place: [],
			gender: '',
			CityBranch: {},
			branch: [],
			branchVal: [],
			branchData: [],
			nationList: [],
			memberTypeData: [],
			member_type_Array: [],
			conhabit: '',
			ABO_type: '',
			densityGradeList: [],
			density_grade: [],
			densityVal: [],
			educationList: [],
			beliefList: [],
			maritaType: [],
			jobList: [],
			gradeList: [],
			interestList: [],
			socialList: [],
			socialArr: [],
			interestArr: [],
			contributionArr: [],
			honorArr: [],
			contributionList: [],
			honorList: [],
			pTitleList: [],
			nativePlaceList: [],
			UrbanList: [],
			urbanValue: '',
			choConhabit: true,
			conhabitEdit: false,
			ruleValidate: {
				cid: [{ required: true, validator: validateCity, trigger: 'change' }],
				branch_id: [{ required: true, validator: validateBranch, trigger: 'change' }],
				phone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
				member_type1: [{ required: true, validator: validateType, trigger: 'change' }],
				weight: [{ required: true, validator: validateWeight, trigger: 'blur' }],
				height: [{ required: true, validator: validateHeight, trigger: 'blur' }],
			},
			power: [],
			proList: [],
			cityList: [],
			city: {},
			store: {},
			storeList: [],
			ABOList: [
				{
					value: 'O',
					label: 'O',
				},
				{
					value: 'A1',
					label: 'A1',
				},
				{
					value: 'A2',
					label: 'A2',
				},
				{
					value: 'B1',
					label: 'B1',
				},
				{
					value: 'B2',
					label: 'B2',
				},
				{
					value: 'A1B1',
					label: 'A1B1',
				},
				{
					value: 'A2B1',
					label: 'A2B1',
				},
				{
					value: 'A1B2',
					label: 'A1B2',
				},
				{
					value: 'A2B2',
					label: 'A2B2',
				},
			],
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
	},
	watch: {
		// 如果 branchArr 发生改变，这个函数就会运行
		member_type_Array() {
			this.Member.member_type_id = this.member_type_Array[0];
			this.Member.member_card_id = this.member_type_Array[1];
		},
	},
	mounted() {
		this.initData();
		this.getUsers();
	},
	methods: {
		// 顾客类型
		changeType() {
			this.typeItemList = this.typeItem[this.Member.member_type1];
		},
		changeItems() {
			if (!this.Member.member_type1) {
				this.$Message.warning('请先选择顾客类型');
			}
		},
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.Member.pid];
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.Member.cid];
		},
		// 点击门店先选择城市
		changeBossBranch() {
			if (!this.Member.cid) {
				this.$Message.warning('请先选择城市');
			}
		},
		// 点击城市先选择省份
		changeBossCity() {
			if (!this.Member.pid) {
				this.$Message.warning('请先选择省份');
			}
		},
		initData() {
			this.nativePlaceList = City;
			memberService.getBase().then((data) => {
				this.densityGradeList = data.density;
				this.educationList = data.education;
				this.beliefList = data.belief;
				this.maritaType = data.marital;
				this.jobList = data.p_job;
				// this.gradeList = data.grade
				data.grade.forEach((item) => {
					this.gradeList.unshift(item);
				});
				this.interestList = data.interest;
				this.socialList = data.social;
				this.contributionList = data.special;
				this.memberTypeData = data.member_type;
				this.honorList = data.honor;
				this.pTitleList = data.p_title;
				this.CityBranch = data.new_branch.need_branch;
				this.UrbanList = data.new_branch.need_city;
				this.proList = data.new_staff_list.pro;
				this.city = data.new_staff_list.city;
				this.store = data.new_staff_list.store;
				this.typeList = data.member_type.list_type1;
				this.typeItem = data.member_type.list_type2;
				this.initDetailData();
			});
		},
		initDetailData() {
			memberService.detail({ member_id: this.memberId }).then((data) => {
				this.Member.mate_card = data.mata_card;
				this.Member.height = data.height;
				this.Member.mate_job = data.mate_job;
				this.interestArr = data.interest;
				this.Member.weight = data.weight;
				this.Member.grade_id = data.grade;
				this.Member.sex = data.sex;
				this.Member.phone = data.phone;
				this.Member.bysjh = data.bysjh;
				this.Member.job = data.job;
				this.Member.job_p = data.job_p;
				this.Member.address = data.address;
				this.Member.density_grade = data.density_grade;
				this.Member.religion = data.religion;
				this.Member.work_unit = data.work_unit;
				this.honorArr = data.honor;
				this.Member.positional = data.positional;
				this.socialArr = data.social;
				this.contributionArr = data.contribution;
				this.Member.density_detail = data.density_detail;
				this.Member.mate_name = data.mate_name;
				this.Member.mate_postion = data.mate_position;
				this.Member.pid = data.pid;
				this.Member.cid = data.cid;
				this.Member.branch_id = data.branch;
				this.Member.head_id = data.head_id;
				this.Member.ABO_type = data.ABO_type;
				this.Member.member_type_id = data.member_type_id;
				this.Member.member_card_id = data.member_card_id;
				this.Member.member_type1 = data.member_type1;
				this.Member.member_type2 = data.member_type2;
				this.Member.jsr_id = data.jsr_id;
				if (this.Member.density_grade) {
					this.densityVal.push(
						Number(this.Member.density_grade),
						Number(this.Member.density_detail),
					);
				} else {
					this.densityVal = [];
				}
				this.member_type_Array.push(
					Number(this.Member.member_type_id),
					Number(this.Member.member_card_id),
				);
				this.native_place = data.native_place.split(',');
				if (typeof data.cohabit === 'number') {
					this.Member.conhabit = JSON.stringify(data.cohabit);
				} else {
					this.Member.conhabit = data.cohabit;
				}
				if (data.education === '0') {
					this.Member.education = '';
				} else {
					this.Member.education = Number(data.education);
				}
				if (data.marita === '0') {
					this.Member.marita = '';
				} else {
					this.Member.marita = Number(data.marita);
				}
				this.changeType();
				this.changePro();
				this.changeCity();
			});
		},
		// 所属分院改变
		handleChangeBranch(value, selectedData) {
			this.branch = selectedData.map((o) => o.label);
			this.Member.branch = this.branch[1];
		},
		handleConhabit(value) {
			if (value === '1') {
				this.choConhabit = true;
				if (!this.Member.density_grade) {
					this.conhabitEdit = true;
				}
			} else {
				this.choConhabit = this.conhabitEdit = false;
				this.densityVal = [];
			}
		},
		// 密度改变
		handleChangeDensity(value) {
			this.Member.density_grade = value[0];
			this.Member.density_detail = value[1];
			this.conhabitEdit = false;
		},
		handleGrade(value) {
			this.Member.grade_id = value;
		},
		// 保存修改数据
		handleSave(name) {
			this.$refs[name].validate((valid) => {
				if (valid) {
					let id = localStorage.getItem('head_id') || this.Member.head_id;
					this.Member.head_id = id;
					this.Member.id_card = sessionStorage.getItem('id_card');
					this.Member.name = sessionStorage.getItem('name');
					this.Member.gender = sessionStorage.getItem('gender');
					this.Member.nation = sessionStorage.getItem('nation');
					this.Member.birthday = sessionStorage.getItem('birthday');
					this.Member.jrrq = sessionStorage.getItem('jrrq');
					this.Member.device_type = sessionStorage.getItem('device_type');
					this.Member.remark = sessionStorage.getItem('remark');
					this.Member.hyzt = sessionStorage.getItem('hyzt');
					this.Member.member_id = this.memberId;
					this.Member.social = JSON.stringify(this.socialArr);
					this.Member.interest = JSON.stringify(this.interestArr);
					this.Member.contribution = JSON.stringify(this.contributionArr);
					this.Member.honor = JSON.stringify(this.honorArr);
					this.Member.member_type2 = JSON.stringify(this.Member.member_type2);
					this.Member.native_place = this.native_place.join(',');

					if (this.Member.education === '') {
						this.Member.education = 0;
					}
					if (this.Member.marita === '') {
						this.Member.marita = 0;
					}
					if (this.Member.conhabit === '1' && this.densityVal.length > 0) {
						memberService.update(this.Member).then(() => {
							this.$Message.success('保存成功');
							this.initDetailData();
						});
					} else {
						this.conhabitEdit = true;
						// this.$Message.error('请选择密度')
					}
					if (this.Member.conhabit === '0' || this.Member.conhabit === '') {
						this.Member.density_grade = '';
						this.Member.density_detail = '';
						this.conhabitEdit = false;
						this.densityVal = [];
						memberService.update(this.Member).then(() => {
							this.initDetailData();
							this.$Message.success('保存成功');
							// this.$router.push({path: '/base/member'})
						});
					}
				} else {
					this.$Message.error('*号为必填项!');
				}
			});
		},
		handleNative(value, selectedData) {
			this.Member.native_place = selectedData.map((o) => o.value).join(',');
		},
		handleEducation(value) {
			this.Member.education = value;
		},
		handleMarita(value) {
			this.Member.marita = value;
		},
		getUsers() {
			familyService.getAllUsers().then((data) => {
				this.userList = data.list;
			});
		},
	},
	created() {
		this.power = this.userInfo.power;
		if (this.Member.conhabit === '') {
			this.choConhabit = false;
			this.densityVal = [];
		}
	},
};
</script>
<style lang="css" scoped>
.scheme-contain .info-box {
  padding: 0 30px;
}
.scheme-contain .symbol-box {
  margin-top: 25px;
}
</style>
